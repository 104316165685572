.edit-page {
  align-items: center;
  overflow: auto;
}

.edit-page .title {
  margin-bottom: 15px;
  margin-top: 15px;

  color: var(--primary-color);
}

.edit-page form {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 25px 50px;
  margin-bottom: 30px;

  width: 95%;
  height: max-content;

  border-radius: 5px;
  border: 1px solid #999;
}

.edit-page form .input-box {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.edit-page form .input-box .title-button-box {
  display: flex;
  align-items: center;
}

.edit-page form .input-box .title-button-box .set-content {
  width: fit-content;
  height: fit-content;
  padding: 8px;
  margin-left: 16px;
  font-size: 20px;
  background-color: var(--primary-color);
  border-radius: 3px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-page form .input-box.image {
  flex-direction: row;
  align-items: flex-end;
}

.edit-page form .input-box.image div {
  width: fit-content;
  display: flex;
  flex-direction: column;
}

.edit-page form .input-box.image div:last-child {
  flex: 1;
}

.edit-page form .input-box.image .select-file {
  height: 40px;
  width: fit-content;
  border: 1px solid #000;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 32px 0 10px;
  border-radius: 4px;
  position: relative;
}

.edit-page form .input-box.image .select-file label {
  cursor: pointer;
}

.edit-page form .input-box.image .select-file .clean-selection {
  position: absolute;
  right: 4px;
  font-size: 1.2rem;
  cursor: pointer;
}

.edit-page form .input-box.image input[type="file"] {
  display: none;
}

.edit-page form .input-box.image span {
  margin: 0 16px 12px 16px;
}

.edit-page form .input-box + div {
  margin-top: 10px;
}

.edit-page form .input-box label {
  font-size: 16px;
}

.edit-page form .input-box input,
.edit-page form .input-box .content-editable,
.edit-page form .input-box select,
.edit-page form .input-box textarea {
  width: 100%;
  height: 40px;

  padding-left: 8px;

  border-radius: 4px;
  border: 1px solid #000;

  transition: 200ms;
}

.edit-page form .input-box textarea {
  padding-top: 8px;
  resize: none;
}

.edit-page form .input-box .content-editable {
  height: initial;
  min-height: 480px;
  resize: vertical;

  padding: 8px 8px 0 8px;
}

.edit-page form .input-box .category-options {
  display: flex;
  align-items: center;

  width: 100%;

  margin-top: 10px;
}

.edit-page form .input-box .category-options .option {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 40px;

  padding: 8px;

  background-color: #eee;
  color: #333;

  border-radius: 3px;

  cursor: pointer;
}

.edit-page form .input-box .category-options .option + button {
  margin-left: 10px;
}

.edit-page form .input-box .category-options .option.checked {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.edit-page form .input-box .category-options .option svg {
  width: 16px;
  height: 16px;

  margin-right: 8px;
}

.edit-page form .input-box .category-options .option label {
  font-size: 15px;
  font-weight: 100;
  cursor: pointer;
}

.edit-page form .input-box select.error,
.edit-page form .input-box input.error,
.edit-page form .input-box .content-editable.error,
.edit-page form .input-box .category-options.error {
  border: 2px solid #aa0055;
}

.edit-page form button {
  max-width: 100%;
  width: 250px;
  height: 40px;

  border-radius: 4px;

  border: 0;
}

.edit-page form .button-submit {
  margin-top: 35px;

  border-radius: 2px;

  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.edit-page form .button-cancel {
  margin-top: 10px;

  background: transparent;
  color: var(--primary-color);
}

@media (max-width: 700px) {
  .edit-page form {
    padding: 10px;
  }

  .edit-page form .input-box .category-options {
    flex-direction: column;
  }

  .edit-page form .input-box .category-options .option + button {
    margin: 0;
    margin-top: 16px;
  }
}
