.confirm-delete {
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  background-color: #33333355;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;
}

.confirm-delete.hidden{
  display: none;
}

.confirm-delete .content {
  background-color: #fff;
  border-radius: 0.3rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 20rem;
  height: fit-content;
}

.confirm-delete .content .actions {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1rem;
}

.confirm-delete .content .actions button {
  background-color: transparent;
  color: var(--primary-color);
  border: 0;
}

.confirm-delete .content .actions button + button {
  margin-left: 1rem;
  font-weight: bold;
}