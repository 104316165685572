.login-page {
  flex-direction: row;
  align-items: center;
}

.login-page .presentation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 50%;
  height: 100%;
}

.login-page .presentation img {
  width: 200px;

  position: absolute;
}

.login-page form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  padding: 25px 50px;
  margin-bottom: 30px;

  width: 50%;
  height: 100%;
}

.login-page form h1 {
  margin-bottom: 30px;
}

.login-page form input {
  width: 20rem;
  max-width: 100%;
  height: 2.5rem;

  padding-left: 0.5rem;

  border-radius: 2px;
  border: 1px solid #000;
}

.login-page form input.error {
  border: 1px solid #aa0055;
}

.login-page form button {
  max-width: 100%;
  width: 20rem;
  height: 2.5rem;

  margin-top: 35px;

  border-radius: 2px;
  border: 0;

  background: var(--primary-color);
  color: var(--secondary-color);
}

.login-page form button:disabled {
  background-color: #ddd;
}
