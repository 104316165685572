@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap");

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
}

html,
body,
:root,
main {
  width: 100vw;
  height: 100vh;
}

html {
  --primary-color: #00157d;
  --secondary-color: #ffffff;
  --primary-light-color: #001ca8;
  --third-color: #f6ff44;
}

main {
  display: flex;
  flex-direction: column;
}

button {
  cursor: pointer;
}

#loading-modal {
  width: 100vw;
  height: 100vh;

  position: fixed;

  z-index: 10000;

  background-color: #ffffff50;
}
