@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap);
.alert-component {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  padding: 20px 10px;
}

.alert-box {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
  min-height: 50px;
  text-align: center;

  background-color: #0f0;
  color: #fff;

  font-weight: bold;

  padding: 10px;

  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.alert-component.error .alert-box {
  background-color: #f00;
}

.confirm-delete {
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  background-color: #33333355;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;
}

.confirm-delete.hidden{
  display: none;
}

.confirm-delete .content {
  background-color: #fff;
  border-radius: 0.3rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 20rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.confirm-delete .content .actions {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1rem;
}

.confirm-delete .content .actions button {
  background-color: transparent;
  color: var(--primary-color);
  border: 0;
}

.confirm-delete .content .actions button + button {
  margin-left: 1rem;
  font-weight: bold;
}
.loading-box {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  width: 100%;
  height: 100%;

  padding: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: initial;
}

.loading-circle {
  box-sizing: content-box;
  width: 30px;
  height: 30px;

  background-color: var(--primary-color);
  border-radius: 50%;
}

.loading-circle + .loading-circle {
  margin-left: 10px;
}

.loading-circle:nth-child(1) {
  animation: grow 0.5s -0.45s ease-in-out alternate infinite;
}

.loading-circle:nth-child(2) {
  animation: grow 0.5s -0.3s ease-in-out alternate infinite;
}

.loading-circle:nth-child(3) {
  animation: grow 0.5s -0.15s ease-in-out alternate infinite;
}

@keyframes grow {
  to {
    transform: scale(1.3);
    opacity: 0.9;
  }
}

.dashboard-page .page-header {
  width: 100%;
  height: 5rem;
  min-height: 5rem;
  background-color: #eee;

  display: flex;
  align-items: center;

  padding-left: 1rem;

  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.dashboard-page .page-header .button-logout {
  position: absolute;
  top: 1.5rem;
  right: 1rem;

  width: 3.5rem;
  height: 2rem;

  border: 0;

  background: transparent;
  color: var(--secondary-color);
}

.dashboard-page > .content {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 1rem 1rem 1rem;

  overflow: auto;
}

.dashboard-page .content .promotion,
.dashboard-page .content .posts {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
}

.dashboard-page .content .content-title {
  margin-bottom: 1rem;
  display: flex;
}

.dashboard-page .content .content-title .button-add {
  border-radius: 50%;
  border: 0;

  width: 2rem;
  height: 2rem;

  margin-left: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--primary-color);
  color: var(--secondary-color);

  transition: 300ms;
}

.dashboard-page .content .content-title .button-add:hover {
  background-color: var(--primary-light-color);
}

.dashboard-page .content .content-title .button-add .plus-icon {
  font-size: 1.2rem;
}

.dashboard-page .content .empty-content {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dashboard-page .content .empty-content .empty-posts-img {
  width: 13rem;
  margin-bottom: 1rem;
}

.dashboard-page .content .grid-posts {
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row: 50px;
  grid-gap: 15px;
}

.dashboard-page .content .grid-posts .box-item {
  display: flex;
  flex-direction: row;

  border: 1px solid #ddd;
  border-radius: 3px;

  padding: 0.5rem;
}

.dashboard-page .content .grid-posts .box-item img {
  margin-right: 1rem;
  height: 100px;
}

.dashboard-page .content .grid-posts .box-item .text-item {
  flex: 4 1;
  margin-right: 10px;
}

.dashboard-page .content .grid-posts .box-item .text-item h3 {
  width: 100%;
}

.dashboard-page .content .grid-posts .box-item .text-item p {
  font-size: 0.8rem;
}

.dashboard-page .content .grid-posts .box-item .box-buttons {
  display: flex;
  flex-direction: row;
}

.dashboard-page .content .grid-posts .box-item button {
  display: flex;
  justify-content: center;

  width: 50px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  background: transparent;
  border: 0;
}

.dashboard-page .content .grid-posts .box-item button:hover {
  color: #555;
}

.dashboard-page .content .see-all {
  width: 10rem;
  height: 2.5rem;
  border-radius: 0.3rem;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  background-color: var(--secondary-color);
  transition: 150ms;
  margin-top: 1rem;
}

.dashboard-page .content .see-all:hover {
  filter: opacity(0.9);
}

/* Toast de mensagem */
.dashboard-page .toast {
  display: flex;
  align-items: center;

  padding: 0.1rem 0.5rem;

  background: #222;
  color: #eee;
  border-radius: 3px;

  opacity: 0.9;

  width: 15rem;
  height: 3rem;

  position: absolute;
  bottom: 1rem;
  left: 1rem;

  transition: opacity 700ms, bottom 400ms;
}

.dashboard-page .toast.hidden {
  opacity: 0;
  bottom: 0;
}

.dashboard-page .toast p {
  margin-left: 0.5rem;
}

@media (max-width: 1000px) {
  .dashboard-page .content {
    padding: 0.2rem;
  }

  .dashboard-page .content .grid-posts {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .dashboard-page .content .grid-posts .box-item {
    width: 100%;
  }

  .dashboard-page .button-add {
    width: 4rem;
    height: 4rem;
  }
}

@media (max-width: 500px) {
  .dashboard-page .content .posts {
    width: 50%;
  }

  .dashboard-page .content .grid-posts .box-item {
    flex-direction: column;
    height: auto;
  }

  .dashboard-page .content .grid-posts .box-item img {
    margin: 0;
    width: 100%;
  }

  .dashboard-page .content .grid-posts .box-item .box-buttons {
    width: 100%;
    margin-top: 0.5rem;
  }

  .dashboard-page .content .grid-posts .box-item .box-buttons button {
    width: 50%;
  }
}

.login-page {
  flex-direction: row;
  align-items: center;
}

.login-page .presentation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 50%;
  height: 100%;
}

.login-page .presentation img {
  width: 200px;

  position: absolute;
}

.login-page form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;

  padding: 25px 50px;
  margin-bottom: 30px;

  width: 50%;
  height: 100%;
}

.login-page form h1 {
  margin-bottom: 30px;
}

.login-page form input {
  width: 20rem;
  max-width: 100%;
  height: 2.5rem;

  padding-left: 0.5rem;

  border-radius: 2px;
  border: 1px solid #000;
}

.login-page form input.error {
  border: 1px solid #aa0055;
}

.login-page form button {
  max-width: 100%;
  width: 20rem;
  height: 2.5rem;

  margin-top: 35px;

  border-radius: 2px;
  border: 0;

  background: var(--primary-color);
  color: var(--secondary-color);
}

.login-page form button:disabled {
  background-color: #ddd;
}

.actions-bar {
  display: flex;
}

.actions-bar button,
.actions-bar input,
.actions-bar select,
.actions-bar div {
  flex: 1 1;
  background-color: #eee;
  border: 0 !important;
  font-size: 16px;
}

.actions-bar .input-font-size {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.5 1;
  width: 1rem;
}

.actions-bar .input-color-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.actions-bar .font-color-input {
  position: absolute;
  opacity: 0;
  z-index: 2;
}

.actions-bar .pseudo-color-input {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.actions-bar .pseudo-color-input .span-color {
  height: 10px;
  width: 10px;
  margin-left: 5px;
  border: 1px solid #000;
}

.image-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  padding: 2rem;
  background-color: #00000050;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-modal-visible {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  min-width: 22rem;
  height: 100%;
  background-color: #fff;
  padding: 1rem;
  border-radius: 0.3rem;
  overflow: auto;
}

.image-modal-visible h2 {
  margin-bottom: 1rem;
}

.image-modal-visible img {
  margin-bottom: 1rem;
  width: 20rem;
}

.image-modal-visible .select-image {
  margin-bottom: 1rem;
  border: 0 !important;
  padding: 0 !important;
  width: 20rem !important;
}

.image-modal-visible .input-alteranative-text,
.image-modal-visible .input-image-url {
  height: 2rem !important;
  width: 20rem !important;
  margin-bottom: 1rem;
}

.image-modal-visible .image-size-inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 20rem;
  margin-bottom: 1.5rem;
}

.image-modal-visible .image-size-inputs input {
  width: 8rem !important;
  height: 2rem !important;
  border-radius: 0.3rem;
}

.image-modal-visible .image-size-inputs span {
  margin: 0 1rem;
}

.image-modal-visible .buttons-box {
  display: flex;
  align-items: flex-end;
  flex: 1 1;
  width: 20rem;
}

.image-modal-visible .buttons-box button {
  flex: 1 1;
  height: 2rem;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border-radius: 0.3rem;
}

.image-modal-visible .buttons-box button:last-child {
  margin-left: 1rem;
  background-color: var(--secondary-color);
  color: var(--primary-color);
}

.video-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  padding: 2rem;
  background-color: #00000050;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-modal-visible {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  min-width: 22rem;
  background-color: #fff;
  padding: 1rem;
  border-radius: 0.3rem;
  overflow: auto;
}

.video-modal-visible h2 {
  margin-bottom: 1rem;
}

.video-modal-visible .input-video-url {
  height: 2rem !important;
  width: 20rem !important;
  margin-bottom: 2rem;
}

.video-modal-visible .video-size-inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 20rem;
  margin-bottom: 1.5rem;
}

.video-modal-visible .video-size-inputs input {
  width: 8rem !important;
  height: 2rem !important;
  border-radius: 0.3rem;
}

.video-modal-visible .video-size-inputs span {
  margin: 0 1rem;
}

.video-modal-visible .buttons-box {
  display: flex;
  align-items: flex-end;
  flex: 1 1;
  width: 20rem;
}

.video-modal-visible .buttons-box button {
  flex: 1 1;
  height: 2rem;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border-radius: 0.3rem;
}

.video-modal-visible .buttons-box button:last-child {
  margin-left: 1rem;
  background-color: var(--secondary-color);
  color: var(--primary-color);
}

.edit-page {
  align-items: center;
  overflow: auto;
}

.edit-page .title {
  margin-bottom: 15px;
  margin-top: 15px;

  color: var(--primary-color);
}

.edit-page form {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 25px 50px;
  margin-bottom: 30px;

  width: 95%;
  height: -webkit-max-content;
  height: max-content;

  border-radius: 5px;
  border: 1px solid #999;
}

.edit-page form .input-box {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.edit-page form .input-box .title-button-box {
  display: flex;
  align-items: center;
}

.edit-page form .input-box .title-button-box .set-content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 8px;
  margin-left: 16px;
  font-size: 20px;
  background-color: var(--primary-color);
  border-radius: 3px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-page form .input-box.image {
  flex-direction: row;
  align-items: flex-end;
}

.edit-page form .input-box.image div {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
}

.edit-page form .input-box.image div:last-child {
  flex: 1 1;
}

.edit-page form .input-box.image .select-file {
  height: 40px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid #000;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 32px 0 10px;
  border-radius: 4px;
  position: relative;
}

.edit-page form .input-box.image .select-file label {
  cursor: pointer;
}

.edit-page form .input-box.image .select-file .clean-selection {
  position: absolute;
  right: 4px;
  font-size: 1.2rem;
  cursor: pointer;
}

.edit-page form .input-box.image input[type="file"] {
  display: none;
}

.edit-page form .input-box.image span {
  margin: 0 16px 12px 16px;
}

.edit-page form .input-box + div {
  margin-top: 10px;
}

.edit-page form .input-box label {
  font-size: 16px;
}

.edit-page form .input-box input,
.edit-page form .input-box .content-editable,
.edit-page form .input-box select,
.edit-page form .input-box textarea {
  width: 100%;
  height: 40px;

  padding-left: 8px;

  border-radius: 4px;
  border: 1px solid #000;

  transition: 200ms;
}

.edit-page form .input-box textarea {
  padding-top: 8px;
  resize: none;
}

.edit-page form .input-box .content-editable {
  height: initial;
  min-height: 480px;
  resize: vertical;

  padding: 8px 8px 0 8px;
}

.edit-page form .input-box .category-options {
  display: flex;
  align-items: center;

  width: 100%;

  margin-top: 10px;
}

.edit-page form .input-box .category-options .option {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 40px;

  padding: 8px;

  background-color: #eee;
  color: #333;

  border-radius: 3px;

  cursor: pointer;
}

.edit-page form .input-box .category-options .option + button {
  margin-left: 10px;
}

.edit-page form .input-box .category-options .option.checked {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.edit-page form .input-box .category-options .option svg {
  width: 16px;
  height: 16px;

  margin-right: 8px;
}

.edit-page form .input-box .category-options .option label {
  font-size: 15px;
  font-weight: 100;
  cursor: pointer;
}

.edit-page form .input-box select.error,
.edit-page form .input-box input.error,
.edit-page form .input-box .content-editable.error,
.edit-page form .input-box .category-options.error {
  border: 2px solid #aa0055;
}

.edit-page form button {
  max-width: 100%;
  width: 250px;
  height: 40px;

  border-radius: 4px;

  border: 0;
}

.edit-page form .button-submit {
  margin-top: 35px;

  border-radius: 2px;

  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.edit-page form .button-cancel {
  margin-top: 10px;

  background: transparent;
  color: var(--primary-color);
}

@media (max-width: 700px) {
  .edit-page form {
    padding: 10px;
  }

  .edit-page form .input-box .category-options {
    flex-direction: column;
  }

  .edit-page form .input-box .category-options .option + button {
    margin: 0;
    margin-top: 16px;
  }
}

.edit-page {
  align-items: center;
  overflow: auto;
}

.edit-page .title {
  margin-bottom: 15px;
  margin-top: 15px;

  color: var(--primary-color);
}

.edit-page form {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 25px 50px;
  margin-bottom: 30px;

  width: 95%;
  height: -webkit-max-content;
  height: max-content;

  border-radius: 5px;
  border: 1px solid #999;
}

.edit-page form .input-box {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.edit-page form .input-box .title-button-box {
  display: flex;
  align-items: center;
}

.edit-page form .input-box .title-button-box .set-content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 8px;
  margin-left: 16px;
  font-size: 20px;
  background-color: var(--primary-color);
  border-radius: 3px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-page form .input-box.image {
  flex-direction: row;
  align-items: flex-end;
}

.edit-page form .input-box.image div {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
}

.edit-page form .input-box.image div:last-child {
  flex: 1 1;
}

.edit-page form .input-box.image .select-file {
  height: 40px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid #000;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 32px 0 10px;
  border-radius: 4px;
  position: relative;
}

.edit-page form .input-box.image .select-file label {
  cursor: pointer;
}

.edit-page form .input-box.image .select-file .clean-selection {
  position: absolute;
  right: 4px;
  font-size: 1.2rem;
  cursor: pointer;
}

.edit-page form .input-box.image input[type="file"] {
  display: none;
}

.edit-page form .input-box.image span {
  margin: 0 16px 12px 16px;
}

.edit-page form .input-box + div {
  margin-top: 10px;
}

.edit-page form .input-box label {
  font-size: 16px;
}

.edit-page form .input-box input,
.edit-page form .input-box .content-editable,
.edit-page form .input-box select,
.edit-page form .input-box textarea {
  width: 100%;
  height: 40px;

  padding-left: 8px;

  border-radius: 4px;
  border: 1px solid #000;

  transition: 200ms;
}

.edit-page form .input-box textarea {
  padding-top: 8px;
  resize: none;
}

.edit-page form .input-box .content-editable {
  height: initial;
  min-height: 480px;
  resize: vertical;

  padding: 8px 8px 0 8px;
}

.edit-page form .input-box .category-options {
  display: flex;
  align-items: center;

  width: 100%;

  margin-top: 10px;
}

.edit-page form .input-box .category-options .option {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 40px;

  padding: 8px;

  background-color: #eee;
  color: #333;

  border-radius: 3px;

  cursor: pointer;
}

.edit-page form .input-box .category-options .option + button {
  margin-left: 10px;
}

.edit-page form .input-box .category-options .option.checked {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.edit-page form .input-box .category-options .option svg {
  width: 16px;
  height: 16px;

  margin-right: 8px;
}

.edit-page form .input-box .category-options .option label {
  font-size: 15px;
  font-weight: 100;
  cursor: pointer;
}

.edit-page form .input-box select.error,
.edit-page form .input-box input.error,
.edit-page form .input-box .content-editable.error,
.edit-page form .input-box .category-options.error {
  border: 2px solid #aa0055;
}

.edit-page form button {
  max-width: 100%;
  width: 250px;
  height: 40px;

  border-radius: 4px;

  border: 0;
}

.edit-page form .button-submit {
  margin-top: 35px;

  border-radius: 2px;

  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.edit-page form .button-cancel {
  margin-top: 10px;

  background: transparent;
  color: var(--primary-color);
}

@media (max-width: 700px) {
  .edit-page form {
    padding: 10px;
  }

  .edit-page form .input-box .category-options {
    flex-direction: column;
  }

  .edit-page form .input-box .category-options .option + button {
    margin: 0;
    margin-top: 16px;
  }
}

.spash-page {
  display: flex;
  align-items: center;
  justify-content: center;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
}

html,
body,
:root,
main {
  width: 100vw;
  height: 100vh;
}

html {
  --primary-color: #00157d;
  --secondary-color: #ffffff;
  --primary-light-color: #001ca8;
  --third-color: #f6ff44;
}

main {
  display: flex;
  flex-direction: column;
}

button {
  cursor: pointer;
}

#loading-modal {
  width: 100vw;
  height: 100vh;

  position: fixed;

  z-index: 10000;

  background-color: #ffffff50;
}

