.alert-component {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  padding: 20px 10px;
}

.alert-box {
  width: fit-content;
  max-width: 100%;
  min-height: 50px;
  text-align: center;

  background-color: #0f0;
  color: #fff;

  font-weight: bold;

  padding: 10px;

  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.alert-component.error .alert-box {
  background-color: #f00;
}
