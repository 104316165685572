.dashboard-page .page-header {
  width: 100%;
  height: 5rem;
  min-height: 5rem;
  background-color: #eee;

  display: flex;
  align-items: center;

  padding-left: 1rem;

  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.dashboard-page .page-header .button-logout {
  position: absolute;
  top: 1.5rem;
  right: 1rem;

  width: 3.5rem;
  height: 2rem;

  border: 0;

  background: transparent;
  color: var(--secondary-color);
}

.dashboard-page > .content {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 1rem 1rem 1rem;

  overflow: auto;
}

.dashboard-page .content .promotion,
.dashboard-page .content .posts {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
}

.dashboard-page .content .content-title {
  margin-bottom: 1rem;
  display: flex;
}

.dashboard-page .content .content-title .button-add {
  border-radius: 50%;
  border: 0;

  width: 2rem;
  height: 2rem;

  margin-left: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--primary-color);
  color: var(--secondary-color);

  transition: 300ms;
}

.dashboard-page .content .content-title .button-add:hover {
  background-color: var(--primary-light-color);
}

.dashboard-page .content .content-title .button-add .plus-icon {
  font-size: 1.2rem;
}

.dashboard-page .content .empty-content {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dashboard-page .content .empty-content .empty-posts-img {
  width: 13rem;
  margin-bottom: 1rem;
}

.dashboard-page .content .grid-posts {
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row: 50px;
  grid-gap: 15px;
}

.dashboard-page .content .grid-posts .box-item {
  display: flex;
  flex-direction: row;

  border: 1px solid #ddd;
  border-radius: 3px;

  padding: 0.5rem;
}

.dashboard-page .content .grid-posts .box-item img {
  margin-right: 1rem;
  height: 100px;
}

.dashboard-page .content .grid-posts .box-item .text-item {
  flex: 4;
  margin-right: 10px;
}

.dashboard-page .content .grid-posts .box-item .text-item h3 {
  width: 100%;
}

.dashboard-page .content .grid-posts .box-item .text-item p {
  font-size: 0.8rem;
}

.dashboard-page .content .grid-posts .box-item .box-buttons {
  display: flex;
  flex-direction: row;
}

.dashboard-page .content .grid-posts .box-item button {
  display: flex;
  justify-content: center;

  width: 50px;
  height: fit-content;

  background: transparent;
  border: 0;
}

.dashboard-page .content .grid-posts .box-item button:hover {
  color: #555;
}

.dashboard-page .content .see-all {
  width: 10rem;
  height: 2.5rem;
  border-radius: 0.3rem;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  background-color: var(--secondary-color);
  transition: 150ms;
  margin-top: 1rem;
}

.dashboard-page .content .see-all:hover {
  filter: opacity(0.9);
}

/* Toast de mensagem */
.dashboard-page .toast {
  display: flex;
  align-items: center;

  padding: 0.1rem 0.5rem;

  background: #222;
  color: #eee;
  border-radius: 3px;

  opacity: 0.9;

  width: 15rem;
  height: 3rem;

  position: absolute;
  bottom: 1rem;
  left: 1rem;

  transition: opacity 700ms, bottom 400ms;
}

.dashboard-page .toast.hidden {
  opacity: 0;
  bottom: 0;
}

.dashboard-page .toast p {
  margin-left: 0.5rem;
}

@media (max-width: 1000px) {
  .dashboard-page .content {
    padding: 0.2rem;
  }

  .dashboard-page .content .grid-posts {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .dashboard-page .content .grid-posts .box-item {
    width: 100%;
  }

  .dashboard-page .button-add {
    width: 4rem;
    height: 4rem;
  }
}

@media (max-width: 500px) {
  .dashboard-page .content .posts {
    width: 50%;
  }

  .dashboard-page .content .grid-posts .box-item {
    flex-direction: column;
    height: auto;
  }

  .dashboard-page .content .grid-posts .box-item img {
    margin: 0;
    width: 100%;
  }

  .dashboard-page .content .grid-posts .box-item .box-buttons {
    width: 100%;
    margin-top: 0.5rem;
  }

  .dashboard-page .content .grid-posts .box-item .box-buttons button {
    width: 50%;
  }
}
