.actions-bar {
  display: flex;
}

.actions-bar button,
.actions-bar input,
.actions-bar select,
.actions-bar div {
  flex: 1;
  background-color: #eee;
  border: 0 !important;
  font-size: 16px;
}

.actions-bar .input-font-size {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.5;
  width: 1rem;
}

.actions-bar .input-color-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.actions-bar .font-color-input {
  position: absolute;
  opacity: 0;
  z-index: 2;
}

.actions-bar .pseudo-color-input {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.actions-bar .pseudo-color-input .span-color {
  height: 10px;
  width: 10px;
  margin-left: 5px;
  border: 1px solid #000;
}

.image-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  padding: 2rem;
  background-color: #00000050;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-modal-visible {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  min-width: 22rem;
  height: 100%;
  background-color: #fff;
  padding: 1rem;
  border-radius: 0.3rem;
  overflow: auto;
}

.image-modal-visible h2 {
  margin-bottom: 1rem;
}

.image-modal-visible img {
  margin-bottom: 1rem;
  width: 20rem;
}

.image-modal-visible .select-image {
  margin-bottom: 1rem;
  border: 0 !important;
  padding: 0 !important;
  width: 20rem !important;
}

.image-modal-visible .input-alteranative-text,
.image-modal-visible .input-image-url {
  height: 2rem !important;
  width: 20rem !important;
  margin-bottom: 1rem;
}

.image-modal-visible .image-size-inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 20rem;
  margin-bottom: 1.5rem;
}

.image-modal-visible .image-size-inputs input {
  width: 8rem !important;
  height: 2rem !important;
  border-radius: 0.3rem;
}

.image-modal-visible .image-size-inputs span {
  margin: 0 1rem;
}

.image-modal-visible .buttons-box {
  display: flex;
  align-items: flex-end;
  flex: 1;
  width: 20rem;
}

.image-modal-visible .buttons-box button {
  flex: 1;
  height: 2rem;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border-radius: 0.3rem;
}

.image-modal-visible .buttons-box button:last-child {
  margin-left: 1rem;
  background-color: var(--secondary-color);
  color: var(--primary-color);
}

.video-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  padding: 2rem;
  background-color: #00000050;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-modal-visible {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  min-width: 22rem;
  background-color: #fff;
  padding: 1rem;
  border-radius: 0.3rem;
  overflow: auto;
}

.video-modal-visible h2 {
  margin-bottom: 1rem;
}

.video-modal-visible .input-video-url {
  height: 2rem !important;
  width: 20rem !important;
  margin-bottom: 2rem;
}

.video-modal-visible .video-size-inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 20rem;
  margin-bottom: 1.5rem;
}

.video-modal-visible .video-size-inputs input {
  width: 8rem !important;
  height: 2rem !important;
  border-radius: 0.3rem;
}

.video-modal-visible .video-size-inputs span {
  margin: 0 1rem;
}

.video-modal-visible .buttons-box {
  display: flex;
  align-items: flex-end;
  flex: 1;
  width: 20rem;
}

.video-modal-visible .buttons-box button {
  flex: 1;
  height: 2rem;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border-radius: 0.3rem;
}

.video-modal-visible .buttons-box button:last-child {
  margin-left: 1rem;
  background-color: var(--secondary-color);
  color: var(--primary-color);
}
