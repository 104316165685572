.loading-box {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  width: 100%;
  height: 100%;

  padding: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: initial;
}

.loading-circle {
  box-sizing: content-box;
  width: 30px;
  height: 30px;

  background-color: var(--primary-color);
  border-radius: 50%;
}

.loading-circle + .loading-circle {
  margin-left: 10px;
}

.loading-circle:nth-child(1) {
  animation: grow 0.5s -0.45s ease-in-out alternate infinite;
}

.loading-circle:nth-child(2) {
  animation: grow 0.5s -0.3s ease-in-out alternate infinite;
}

.loading-circle:nth-child(3) {
  animation: grow 0.5s -0.15s ease-in-out alternate infinite;
}

@keyframes grow {
  to {
    transform: scale(1.3);
    opacity: 0.9;
  }
}
